import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import checkZip from '../../utils/checkZip'; 
import CurrentCityContext from '../../CurrentCityContext';
import serviceImage2 from '../../assets/image/service-meal-prep.png';

function SingleServiceSidebar({ cityData, locationInfo }) {
    const [zipCode, setZipCode] = useState('');
    const navigate = useNavigate(); // Hook for programmatic navigation

    const handleZipChange = (e) => {
        setZipCode(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        await checkZip(zipCode, navigate).catch((error) => {
            console.error('Error in checkZip:', error); // Log any errors from checkZip
        });
    };  

    const { currentCity } = useContext(CurrentCityContext);

    // Function to create URL with city prefix
    const createCityLink = (path) => {
        return currentCity ? `/${currentCity}${path}` : path;
    };

    return (
    <>
        <div className="col col-lg-3">
            <div className="d-flex flex-column gap-3">
                <div className="bg-light p-4 rounded-3">
                    <h5 className="font-1 fw-bold mb-3">All Services</h5>
                    <div className="list-group rounded-0" style={{gap: 10}}>
                        <NavLink
                            to={createCityLink("/care-services/in-home-care")}
                            type="button" 
                            activeclasscame="active"
                            className="list-group-item list-group-item-action shadow "
                        >
                            <i className="fa-solid fa-chevron-right me-2"></i> 
                            In-Home Care
                        </NavLink>
                        <NavLink
                            to={createCityLink("/care-services/companion-care")}
                            type="button" 
                            className="list-group-item list-group-item-action shadow "
                        >
                            <i className="fa-solid fa-chevron-right me-2"></i> 
                            Companion Care
                        </NavLink>
                        <NavLink
                            to={createCityLink("/care-services/homemaking")}
                            type="button" 
                            className="list-group-item list-group-item-action shadow "
                        >
                            <i className="fa-solid fa-chevron-right me-2"></i> 
                            Homemaking
                        </NavLink>
                        <NavLink
                            to={createCityLink("/care-services/memory-care")}
                            type="button" 
                            className="list-group-item list-group-item-action shadow "
                        >
                            <i className="fa-solid fa-chevron-right me-2"></i> 
                            Memory Care
                        </NavLink>
                        <NavLink
                            to={createCityLink("/care-services/private-duty-care")}
                            type="button" 
                            className="list-group-item list-group-item-action shadow "
                        >
                            <i className="fa-solid fa-chevron-right me-2"></i> 
                            Personal Care
                        </NavLink>
                        <NavLink
                            to={createCityLink("/care-services/home-nursing")}
                            type="button" 
                            className="list-group-item list-group-item-action shadow "
                        >
                            <i className="fa-solid fa-chevron-right me-2"></i> 
                            Home Nursing Care
                        </NavLink>
                    </div>
                </div>
                <div className="position-relative rounded-3 overflow-hidden p-4"
                    style={{
                        backgroundImage: `url(${serviceImage2})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                    }}>
                    <div className="image-overlay-2"></div>
                    <div className="position-relative w-100 d-flex flex-column justify-content-center align-items-center text-white p-4 text-center"
                        style={{ zIndex: 2 }}>
                        <h4 className="font-1 fw-bold lh-1">Need Any Help ??</h4>
                        <span className="font-1 fw-bold fs-4">+123-456-789</span>
                        <a href="contact.html" type="button" className="btn btn-dark px-4 py-3 fs-5">Contact Us</a>
                    </div>
                </div>
                <div className="bg-light p-4 rounded-3">
                    <h5 className="font-1 fw-bold ">Downloads</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut elit tellus.</p>
                    <div className="list-group rounded-0" style={{ gap: '0.65rem' }}>
                        <a href="/"
                            className="list-group-item list-group-item-action bg-accent-color text-white shadow">
                            <i className="fa-solid fa-file-pdf me-2"></i> Brochure
                        </a>
                        <a href="/"
                            className="list-group-item list-group-item-action bg-accent-color text-white shadow">
                            <i className="fa-solid fa-file-pdf me-2"></i> Company Detail
                        </a>
                        <a href="/"
                            className="list-group-item list-group-item-action bg-accent-color text-white shadow">
                            <i className="fa-solid fa-file-pdf me-2"></i> Costumer Policies
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default SingleServiceSidebar;