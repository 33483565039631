import React, { useState, useEffect, useContext } from 'react';
import CurrentCityContext from '../../CurrentCityContext';
import { Link, NavLink } from 'react-router-dom'; 
import { fetchCountryFromIP } from '../../utils/fetchCountryFromIP';
import headerLogoUSA from '../../assets/image/logo-usa.png';
import headerLogoCanada from '../../assets/image/logo-canada.png';
import headerLogo from '../../assets/image/logo.png';
import { MdLocalPhone } from "react-icons/md";
import { FiChevronsRight } from "react-icons/fi";

function Header({ cityData, locationInfo }) {
    const { currentCity } = useContext(CurrentCityContext);
    const [country, setCountry] = useState('');
/* TODO: REMOVE IPFECH COMMENTOUT SO REAL LOGO FLAG CAN SHOW */
    {/*}
     useEffect(() => {
         fetchCountryFromIP().then(ipData => {
             if (ipData) {
                 setCountry(ipData.country_name);
                 console.log('User location:', ipData.city, ipData.region_code, ipData.postal);
             }
         });
    }, []);
*/}
    const renderLogo = () => {
        if (country === 'United States') {
            return <img src={headerLogoUSA} width="250" alt="GentiCare USA" />;
        } else if (country === 'Canada') {
            return <img src={headerLogoCanada} width="250" alt="GentiCare Canada" />;
        }
        // Default logo if country is neither USA nor Canada, or if not yet determined
        return <img src={headerLogo} width="200" alt="GentiCare Logo" />;
    };

    // Function to create URL with city prefix
    const createCityLink = (path) => {
        return currentCity ? `/${currentCity}${path}` : path;
    };

    return (
    <>
        <section className="bg-accent-color-1 py-3">
        <div className="r-container">
            <div className="d-flex flex-lg-row flex-column justify-content-lg-between justify-content-center gap-3">
                <ul className="d-flex m-0 flex-lg-row flex-column justify-content-center align-items-center gap-3 text-white font-2 text-center text-lg-start"
                    style={{
                        listStyle: 'none'
                    }}
                    >
                    <li
                        style={{
                            fontSize: '.95rem',
                            marginLeft: '-20px'
                        }} 
                    > 
                         Serving {locationInfo.location_name} and surrounding areas
                    </li>
                </ul>
                <div className="social-container justify-content-center">
                    <ul className="d-flex m-0 flex-lg-row flex-column justify-content-center align-items-center gap-3 text-white font-2 text-center text-lg-start"
                        style={{
                            listStyle: 'none'
                        }}
                        >
                        <li
                            style={{
                                fontSize: '1.2rem'
                            }} 
                        > 
                            Local office phone number:
                            <MdLocalPhone 
                                style={{
                                    marginLeft: '0.5rem',
                                    fontSize: '1.35rem',
                                    marginRight: '0.25rem'
                                }} 
                            />
                            <Link 
                                className="text-white fw-semibold" 
                                to={'tel:'+locationInfo.tracking_phone}
                            >
                                {locationInfo.tracking_phone}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section className="sticky-top bg-white">
        <div className="r-container">
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    {/* <a className="navbar-brand" href="#"> */}
                        <div className="logo-container">
                        <Link to={createCityLink("/")}>
                            {renderLogo()}
                        </Link>
                        </div>
                    {/* </a> */}
                    <button className="navbar-toggler accent-color border-0" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        Menu <i className="fa-solid fa-bars-staggered"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0 font-2 fw-semibold gap-lg-3">
                            <li className="nav-item">
                                <NavLink to={createCityLink("/")} className="nav-link" end activeclasscame="active">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={createCityLink("/services")} activeclasscame="active" className="nav-link">Services</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={createCityLink("/rates")} activeclasscame="active" className="nav-link">Rates</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={createCityLink("/quote")} activeclasscame="active" className="nav-link">Contact</NavLink>
                            </li>
                        </ul>
                            <Link to={createCityLink("/quote")} className="btn button bg-accent-color fw-semibold">
                                Get Free Care Quote
                                <FiChevronsRight />
                            </Link>
                    </div>
                </div>
            </nav>
        </div>
    </section>
    </>
    );
}

export default Header;