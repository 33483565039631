import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import checkZip from '../../utils/checkZip'; 
import CurrentCityContext from '../../CurrentCityContext';
import serviceImage1 from '../../assets/image/service-companionship.png';
import serviceImage3 from '../../assets/image/service-homemaking.png';
import SingleServiceSidebarCorp from './SingleServiceSidebarCorp';

function SingleServiceSectionCorp({ cityData, locationInfo }) {
    const [zipCode, setZipCode] = useState('');
    const navigate = useNavigate(); // Hook for programmatic navigation

    const handleZipChange = (e) => {
        setZipCode(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        await checkZip(zipCode, navigate).catch((error) => {
            console.error('Error in checkZip:', error); // Log any errors from checkZip
        });
    };  

    const { currentCity } = useContext(CurrentCityContext);

    // Function to create URL with city prefix
    const createCityLink = (path) => {
        return currentCity ? `/${currentCity}${path}` : path;
    };

    return (
    <>
        <section className="section">
            <div className="r-container">
                <div className="d-flex flex-lg-row flex-column-reverse gap-3">
                    <SingleServiceSidebarCorp />
                    <div className="col col-lg-9">
                        <div className="d-flex flex-column gap-3">
                            <div className="d-flex flex-column mb-3">
                                <h3 className="font-1 fw-bold">Service Descriptions</h3>
                                <img 
                                src={serviceImage1} 
                                alt='{locationInfo.location_name}'
                                className="img-fluid" />
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In feugiat in mi sit amet
                                    faucibus. Quisque commodo, est ut blandit pharetra, sem augue sagittis metus, vel
                                    dictum
                                    lectus augue nec lorem. Aenean hendrerit tincidunt turpis, ac mollis mi mollis in.
                                    Donec
                                    posuere non velit at egestas. Sed a tortor semper, semper enim sit amet, interdum
                                    massa.
                                    Suspendisse quis libero eu dolor ornare lobortis. Donec id blandit purus.
                                </p>
                                <div className="row row-cols-1 row-cols-lg-3">
                                    <div className="col">
                                        <div
                                            className="card with-border-bottom border-0 shadow d-flex flex-column justify-content-center  align-items-center p-2">
                                            <h3 className="accent-color font-1 fw-bold lh-1">50+</h3>
                                            <h5 className="font-1 fw-bold">Nursing Staff</h5>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div
                                            className="card with-border-bottom border-0 shadow d-flex flex-column justify-content-center  align-items-center p-2">
                                            <h3 className="accent-color font-1 fw-bold lh-1">250+</h3>
                                            <h5 className="font-1 fw-bold">Completed Cases</h5>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div
                                            className="card with-border-bottom border-0 shadow d-flex flex-column justify-content-center  align-items-center p-2">
                                            <h3 className="accent-color font-1 fw-bold lh-1">20+</h3>
                                            <h5 className="font-1 fw-bold">Senior Doctors</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column mb-3">
                                <h3 className="font-1 fw-bold">Service We Provide</h3>
                                <p>
                                    Mauris egestas est vel lectus sagittis gravida. Maecenas nec mi id nunc pulvinar
                                    tincidunt ac nec ante. Aenean venenatis cursus ex. Donec auctor euismod sem, vel
                                    ultricies augue. Quisque sollicitudin enim sed enim fringilla rhoncus. Etiam sit
                                    amet
                                    imperdiet tortor, id iaculis felis. Donec mollis magna eu fringilla consequat.
                                </p>
                            </div>
                            <div className="d-flex flex-lg-row flex-column mb-3">
                                <div className="col">
                                    <div className="position-relative">
                                        <img src={serviceImage3} alt="" className="img-fluid" />
                                        <div
                                            className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center">
                                            <button type="button" className="btn request-loader" data-bs-toggle="modal"
                                                data-bs-target="#videomodal">
                                                <i className="fa-solid fa-play"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="d-flex flex-column ps-3">
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus,
                                            luctus nec ullamcorper mattis, pulvinar dapibus.
                                        </p>
                                        <div className="d-flex flex-column gap-1">
                                            <div className="d-flex flex-row gap-2 align-items-center">
                                                <i className="fa-solid fa-square-check accent-color"></i>
                                                Suspendisse porta porttitor rhoncus
                                            </div>
                                            <div className="d-flex flex-row gap-2 align-items-center">
                                                <i className="fa-solid fa-square-check accent-color"></i>
                                                Etiam dictum condimentum metus
                                            </div>
                                            <div className="d-flex flex-row gap-2 align-items-center">
                                                <i className="fa-solid fa-square-check accent-color"></i>
                                                Proin blandit sodales diam facilisis
                                            </div>
                                            <div className="d-flex flex-row gap-2 align-items-center">
                                                <i className="fa-solid fa-square-check accent-color"></i>
                                                Class aptent taciti sociosqu ad
                                            </div>
                                            <div className="d-flex flex-row gap-2 align-items-center">
                                                <i className="fa-solid fa-square-check accent-color"></i>
                                                Nulla Ut Nibh Ut Mi Accumsan
                                            </div>
                                            <div className="d-flex flex-row gap-2 align-items-center">
                                                <i className="fa-solid fa-square-check accent-color"></i>
                                                Suspendisse Sodales Nibh
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <h3 className="font-1 fw-bold">Frequently Asked Questions</h3>
                                <div className="accordion d-flex flex-column gap-2" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button fw-bold" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="false"
                                                aria-controls="collapseOne">
                                                How To Change My Photo From Admin Dashboard ?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Far far away, behind the word mountains, far from the countries Vokalia
                                                and Consonantia, there live the blind texts. Separated they live in
                                                Bookmarksgrove right at the coast
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button fw-bold" type="button"
                                                aria-controls="collapseTwo">
                                                How To Change My Password Easily ?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse show"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button fw-bold " type="button"
                                                aria-controls="collapseThree">
                                                How To Change My Subscription Plan Using PayPal
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse show"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    );
}

export default SingleServiceSectionCorp;