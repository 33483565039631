import React from 'react';
import HeroSlim from '../../components/Hero/HeroSlim';
import SingleServiceSection from '../../components/Services/SingleServiceSection';

function Homemaking({ cityData, locationInfo }) {
  return (
    <>
      <HeroSlim
        cityData={cityData}
        locationInfo={locationInfo}
        heroTitle="Homemaking"
      />
      <SingleServiceSection
        cityData={cityData}
        locationInfo={locationInfo}
      />
    </>
  );
}

export default Homemaking;