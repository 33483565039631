import React from 'react';
import HeroSlim from '../../components/Hero/HeroSlim';
import SingleServiceSectionCorp from '../../components/Services/SingleServiceSectionCorp';
import HeaderCorporate from '../../components/Header/HeaderCorporate';

function HomeNursingCareCorp({ cityData, locationInfo }) {
  return (
    <>
      <HeaderCorporate />
      <HeroSlim
        cityData={cityData}
        locationInfo={locationInfo}
        heroTitle="Home Nursing Care"
      />
      <SingleServiceSectionCorp
        cityData={cityData}
        locationInfo={locationInfo}
      />
    </>
  );
}

export default HomeNursingCareCorp;